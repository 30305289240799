import styled from 'styled-components';

export const HeaderContainer = styled.div`
  position: relative;
  z-index: 20;
  padding-bottom: 70px;
  width: 100vw;
`;

export const StyledHeader = styled.header`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.4); 
  box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.1);
`;

export const StyledH1 = styled.h1`
  font-family: ${({ theme }) => theme.fonts.bodoniModa};
  font-weight: 900;
  margin: 0;
  font-size: 22px;

  @media (min-width: 992px) {
    font-size: 32px;
  }
  
  a {
    color: ${({ theme }) => theme.colors.sapphireBlue};
    text-decoration: none;
  }
`;