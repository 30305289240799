import React from 'react';
import { Link } from 'gatsby';
import { StyledMenu, MenuItem, MenuDropdown } from './DesktopMenuStyles';

const Menu = () => (
  <StyledMenu>
    <MenuItem><Link to="/bioPage">Bio</Link></MenuItem>
    <MenuDropdown>Music
      <ul>
        <MenuItem><Link to="/albums">Albums</Link></MenuItem>
        <MenuItem><Link to="/videos">Videos</Link></MenuItem>
      </ul>
    </MenuDropdown>
    <MenuItem><Link to="/hireChris">Hire Chris</Link></MenuItem>
    <MenuItem><Link to="/gigs">Gigs</Link></MenuItem>
    <MenuItem><Link to="/contact">Contact</Link></MenuItem>
  </StyledMenu>
);

export default Menu;