import styled from 'styled-components';

export const AccordionItem = styled.ul`
  height: ${props => props.accordionIsOpen ? '130px' : '0'};
  opacity:  ${props => props.accordionIsOpen ? '1' : '0'};
  transition: all 0.3s;
  list-style-type: none;
  visibility: ${props => props.accordionIsOpen ? 'visible' : 'hidden'};
`;

export const AccordionHeader = styled.div`
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  font-size: 32px;
  margin-bottom: 32px;
  text-align: center;
  position: relative;

  .open-symbol {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 20px;
    height: 2px;
    background: ${({ theme }) => theme.colors.white};
  }

  .minus {
    transform: translateY(-50%) rotate(90deg);
  }
`;