import React from "react";
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faBandcamp,
  faSpotify,
  faFacebookF,
  faTwitter,
  faItunesNote
} from '@fortawesome/free-brands-svg-icons'

import { StyledFooter, StyledUl, StyledDetails } from './footerStyles';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query socialLinks {
      allContentfulSocialMediaLinks {
        edges {
          node {
            appleMusicUrl
            bandcampUrl
            facebookUrl
            instagramUrl
            spotifyUrl
            twitterUrl
          }
        }
      }
    }
  `)

  const allLinks = data.allContentfulSocialMediaLinks.edges[0].node

  return (
    <StyledFooter>
      <Container>
        <Row className="d-md-flex align-items-center">
          <Col xs={12} lg={4}>
            <StyledUl>
              <li><a href={allLinks.instagramUrl} target="_blank"><FontAwesomeIcon icon={faInstagram} className="icon" /></a></li>
              <li><a href={allLinks.appleMusicUrl} target="_blank"><FontAwesomeIcon icon={faItunesNote} className="icon" /></a></li>
              <li><a href={allLinks.bandcampUrl} target="_blank"><FontAwesomeIcon icon={faBandcamp} className="icon bandcamp" /></a></li>
              <li><a href={allLinks.spotifyUrl} target="_blank"><FontAwesomeIcon icon={faSpotify} className="icon spotify" /></a></li>
              <li><a href={allLinks.facebookUrl} target="_blank"><FontAwesomeIcon icon={faFacebookF} className="icon" /></a></li>
              <li><a href={allLinks.twitterUrl} target="_blank"><FontAwesomeIcon icon={faTwitter} className="icon" /></a></li>
            </StyledUl>
          </Col>
          <Col xs={12} lg={{ span: 4, offset: 4 }}>
            <StyledDetails>
              <p> © {new Date().getFullYear()} Chris Platt</p>
							<Link to="/privacyPolicy" className="privacy-policy">Privacy Policy</Link>
              <p>Site by <a href="mailto:pinkavocadodigitalagency@gmail.com">Pink Avocado</a></p>
            </StyledDetails>
        </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
