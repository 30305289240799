import React, { useState } from 'react';
import { AccordionItem, AccordionHeader } from './AccordionStyles'; 

const Accordion = ({children, headerTitle}) => {
  const [accordionIsOpen, setAccordionIsOpen] = useState(false);

  return(
    <div>
      <AccordionHeader onClick={() => setAccordionIsOpen(!accordionIsOpen)}>
        {headerTitle} 
        
        <div className="open-symbol">
          <span className="plus"></span>
          { accordionIsOpen ? null : <span className="minus"></span> }
        </div>
      </AccordionHeader>
      <AccordionItem accordionIsOpen={accordionIsOpen} >
        {children}
      </AccordionItem>
    </div>
  )
};

export default Accordion;
