/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header/header";
import Footer from "./Footer/footer";

import "bootstrap/dist/css/bootstrap-grid.min.css";

import "../theme/fontawesome/css/all.min.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [menuOpen, setMenuOpen] = useState(false);

  const handleOverlayMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    menuOpen && (document.body.style.overflow = 'hidden');
    !menuOpen && (document.body.style.overflow = 'visible');
  }, [menuOpen]);

  return (
    <>
      <div className="site">
        <Header siteTitle={data.site.siteMetadata?.title || `Chris Platt`} handleOverlayMenu={handleOverlayMenu} menuOpen={menuOpen} />
          <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout;
