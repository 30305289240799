import styled from 'styled-components';

export const StyledMenu = styled.ul`
  height: 100%;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;
`;

export const MenuItem = styled.li`
  margin-right: 32px;
  &:hover > a {
    font-weight: 600;
  }

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.sapphireBlue};
    font-family: ${({ theme }) => theme.fonts.roboto};
    font-weight: 400;
  }
`;

export const MenuDropdown = styled(MenuItem)`
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.sapphireBlue};
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  position: relative;

  &::after {
    content: '';
    width: 150%;
    height: 32px;
    position: absolute;
    left: 0;
    transform: translateY(50%);
  }

  &:hover {
    font-weight: 600;
  }

  &:hover > ul {
    opacity: 1;
    visibility: visible;
  }

  > ul {
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 44px;
    list-style-type: none;
    padding: 6px 16px 6px 4px;
    z-index: 50;
    -webkit-box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.15);

    > li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;