import styled from 'styled-components';

export const StyledMobileMenu = styled.div`
  position: fixed;
  height: 100vh;
  left: 0;
  width: 100vw;
  opacity: ${props => props.menuOpen ? '1' : '0'};
  visibility: ${props => props.menuOpen ? 'visible' : 'hidden'};
  background-color: ${({ theme }) => theme.colors.sapphireBlue};
  transform: ${props => props.menuOpen ? `translateY(70px)` : `translateY(90px)`};
  transition: all 0.3s;
  padding-bottom: 100px;
  overflow: scroll;
`;

export const StyledMenu = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  height: 50vh;
`;

export const MenuItem = styled.li`
  margin-bottom: 32px;
  text-align: center;

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.roboto};
    font-weight: 400;
    font-size: 32px;
  }
`;
