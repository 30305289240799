import React from 'react';
import { HamburgerButton, StyledSpan, HamburgerTop, HamburgerMiddle, HamburgerBottom  } from './HamburgerStyles';

const Hamburger = ( { handleOverlayMenu, menuOpen } ) => (
  <HamburgerButton onClick={() => handleOverlayMenu()}>
    <HamburgerTop menuOpen={menuOpen}></HamburgerTop>
    <HamburgerMiddle menuOpen={menuOpen}></HamburgerMiddle>
    <HamburgerBottom menuOpen={menuOpen}></HamburgerBottom>
  </HamburgerButton>
)

export default Hamburger;