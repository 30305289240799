import styled from 'styled-components';

export const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.colors.sapphireBlue};
  padding: 32px 0;

  @media (min-width: 1024px) {
    padding: 48px 0;
  }

  p:first-child {
    margin-bottom: 10px;
  }

	p:last-child {
    margin-top: 10px;
  }
`;

export const StyledUl = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0 auto 16px;
  width: 90%;
  max-width: 360px;

  @media (min-width: 1024px) {
    margin: 0;
  }

  .icon {
    color: ${({ theme }) => theme.colors.sapphireBlue};
    background-color: ${({ theme }) => theme.colors.white};
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 3px;
  }

  .icon.spotify {
    background-color: ${({ theme }) => theme.colors.sapphireBlue};
    color: ${({ theme }) => theme.colors.white};
    padding: 0;
  }
  .icon.bandcamp {
    background-color: ${({ theme }) => theme.colors.sapphireBlue};
    color: ${({ theme }) => theme.colors.white};
    padding: 0;
  }
`;

export const StyledDetails = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: 14px;

  @media (min-width: 1024px) {
    font-size: 18px;
    text-align: right;
  }

  a {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;
