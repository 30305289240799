import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap';

import DesktopMenu from '../DesktopMenu/DesktopMenu';
import MobileMenu from '../MobileMenu/MobileMenu';
import Hamburger from '../Hamburger/Hamburger';
import { HeaderContainer, StyledHeader, StyledH1,  } from './headerStyles';

const Header = ( { siteTitle, handleOverlayMenu, menuOpen } ) => {
  return (
    <HeaderContainer>
      <StyledHeader>
        <Container>
          <Row>
            <Col xs={8} lg={6}>
              <StyledH1>
                <Link to="/">
                  {siteTitle}
                </Link>
              </StyledH1></Col>
            <Col lg={6} className="d-none d-lg-block">
              <DesktopMenu />
            </Col>
            <Col xs={4} className="d-lg-none d-flex justify-content-end align-items-center">
              <Hamburger 
                handleOverlayMenu={handleOverlayMenu} 
                menuOpen={menuOpen} 
              />
            </Col>
          </Row>
        </Container>
      </StyledHeader>
      <MobileMenu menuOpen={menuOpen} handleOverlayMenu={handleOverlayMenu} />
    </HeaderContainer>
  )
}

export default Header;
