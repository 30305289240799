import styled from 'styled-components';

export const HamburgerButton = styled.button`
  border: none;
  position: relative;
  width: 42px;
  height: 26px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  outline: none;
`;

export const StyledSpan = styled.span`
  display: block;
  height: 2px;
  background: ${({ theme }) => theme.colors.sapphireBlue};
  width: 100%;
  position: absolute;
  transition: all 0.3s;
`;

export const HamburgerTop = styled(StyledSpan)`
  top: ${props => props.menuOpen ? '50%' : 'auto'};
  transform: ${props => props.menuOpen ? `rotate(45deg)` : `rotate(0deg)`};
`;

export const HamburgerMiddle = styled(StyledSpan)`
  transform: translateY(11px);
  opacity: ${props => props.menuOpen ? '0' : '1'};
`;

export const HamburgerBottom = styled(StyledSpan)`
  transform: translateY(22px);
  top: ${props => props.menuOpen ? '50%' : 'auto'};
  transform: ${props => props.menuOpen ? `rotate(-45deg)` : null};
`;