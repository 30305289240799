import React from "react";
import { Link } from "gatsby";
import { StyledMobileMenu, StyledMenu, MenuItem } from './MobileMenuStyles';
import Accordion from '../Accordion/Accordion';

const MobileMenu = ( {menuOpen, handleOverlayMenu} ) => {
  return(
    <StyledMobileMenu menuOpen={menuOpen} className="d-flex justify-content-center align-items-center">
      <StyledMenu>
        <MenuItem><Link to="/" onClick={handleOverlayMenu}>Home</Link></MenuItem>
        <MenuItem><Link to="/bioPage" onClick={handleOverlayMenu}>Bio</Link></MenuItem>

        <Accordion headerTitle={'Music'}>
          <MenuItem><Link to="/albums" onClick={handleOverlayMenu}>Albums</Link></MenuItem>
          <MenuItem><Link to="/videos" onClick={handleOverlayMenu}>Videos</Link></MenuItem>
        </Accordion> 

        <MenuItem><Link to="/hireChris" onClick={handleOverlayMenu}>Hire Chris</Link></MenuItem>
        <MenuItem><Link to="/gigs" onClick={handleOverlayMenu}>Gigs</Link></MenuItem>
        <MenuItem><Link to="/contact" onClick={handleOverlayMenu}>Contact</Link></MenuItem>
      </StyledMenu>
    </StyledMobileMenu>
  );
};

export default MobileMenu;
